import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "components/Layout";
import { isValidEmail } from "utils/common";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    interested: [],
    name: "",
    email: "",
    subject: "",
    phoneNumber: "",
  });

  const handleInputChange = (e) => {
    setFieldValues({
      ...fieldValues,
      [e.target.name]: e.target.value,
    });
  };

  const resetFields = () => {
    setFieldValues({
      ...fieldValues,
      interested: [],
      name: "",
      email: "",
      subject: "",
      phoneNumber: "",
    });
  };

  const handleSubmit = async () => {
    if (!fieldValues.name) {
      toast("Your name is required", {
        type: "error",
      });
    } else if (!fieldValues.email) {
      toast("Your email is required", {
        type: "error",
      });
    } else if (!isValidEmail(fieldValues.email)) {
      toast("Enter valid email", {
        type: "error",
      });
    } else {
      try {
        setLoading(true);
        // Change here Base URL of contact us API
        const response = await axios.post(
          "https://presale-backend-nine.vercel.app/api/contactUs",
          fieldValues
        );
        if (response.data?.status) {
          toast("Your email send!", {
            type: "success",
          });
          resetFields();
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast("Something is wrong. Please try again.", {
          type: "error",
        });
      }
    }
  };

  return (
    <Layout>
      <div className="page-title mb-0">
        <div className="container">
          <h2 className="data-slide-up">Contact</h2>
          <h4 className="data-slide-up">
            Have something to say? Our team is all ears.
          </h4>
        </div>
      </div>
      <section className="contact pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 wow fadeInUp">
              <div className="contact-form">
                <form id="contact" name="contact" method="post">
                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      id="name"
                      autocomplete="off"
                      required
                      value={fieldValues.name}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>

                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="Your Email"
                      name="email"
                      id="email"
                      autocomplete="off"
                      required
                      value={fieldValues.email}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>

                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="Your Phone Number"
                      name="phoneNumber"
                      id="phoneNumber"
                      autocomplete="off"
                      required
                      value={fieldValues.phoneNumber}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>

                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="How Can We Help You?"
                      name="subject"
                      id="subject"
                      autocomplete="off"
                      required
                      value={fieldValues.subject}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>

                  <div className="form-group">
                    {/* <button id="submit" type="submit" name="submit"><strong>Submit Now<b></b> <i></i></strong></button> */}
                    <input
                      type="button"
                      value={loading ? "Loading..." : "Submit Now"}
                      id="send"
                      onClick={handleSubmit}
                    />
                  </div>
                  <span id="c_error_message" className="text-danger"></span>
                  <span id="c_success_message" className="text-success"></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Contact;
